import './index.css';
import * as serviceWorker from './serviceWorker';
import { VehicleLocation } from './muni';

var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
 
mapboxgl.accessToken = 'pk.eyJ1IjoiY2FsdmluZm8iLCJhIjoiY2p2MWV6a3B1MGJwYTQzcWp6MjhvYWtkcCJ9.VbDuC38XDd7p86Wh39uISw';
var el = document.getElementById('map')
var map = new mapboxgl.Map({
  container: el,
  style: 'mapbox://styles/mapbox/streets-v11',
  center: [-122.4159034, 37.7795744],
  zoom: 14
});

map.on('load', () => {
    map.loadImage(process.env.PUBLIC_URL + '/train-icon.png', (err: Error, image: ImageData) => {
        if (err) {
            console.error('An error occurred', err);
            return
        }

        map.addImage('train-icon', image)
    })

    map.addLayer({
        "id": "points",
        "type": "symbol",
        "source": {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": []
            }
        },
        "layout": {
            "icon-allow-overlap": true,
            "icon-image": "train-icon",
            "icon-size": 0.4,
            "icon-rotate": {
                type: "identity",
                property: "rotation"
            },
            "text-field": "{title}",
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 0],
            "text-anchor": "center"
        }
    });
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const query = `{
    vehicles {
        vehicleId
        route
        direction
        speed
        latitude
        longitude
        secondsOld
    }
}`

function run() {
    fetch('https://gateway-api-27zk6yeulq-uc.a.run.app/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        body: JSON.stringify({
        query,
        variables: {}
        })
    })
    .then((r) => r.json())
    .then(data => {
        let vehicles = data.data.vehicles;
        if (!vehicles) {
            console.log('No vehicles found...')
            return
        }
        let points = vehicles.map((v: VehicleLocation) => {
            return {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [v.longitude, v.latitude]
                },
                properties: {
                    title: v.route,
                    rotation: v.direction === "DIRECTION_OUTBOUND" ? 0 : 180
                }
            }
        })

        let source = map.getSource('points');
        if (!source) return;

        map.getSource('points').setData({
            "type": "FeatureCollection",
            "features": points
        });
    });
}

setInterval(run, 2000)
